import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Button } from 'design-react-kit/dist/design-react-kit';
import { SelectInput } from '@italia/components';
import { TextFilter } from '@italia/components/ItaliaTheme/Blocks/Common/SearchFilters';

const messages = defineMessages({
  search_keyword: {
    id: 'rubrica_search_keyword',
    defaultMessage: 'Digitare il nome della persona, la sede o il dipartimento',
  },
  sede: {
    id: 'rubrica_sede',
    defaultMessage: 'Sede',
  },
  dipartimento: {
    id: 'rubrica_dipartimento',
    defaultMessage: 'Dipartimento',
  },
  find: {
    id: 'find',
    defaultMessage: 'Cerca',
  },
});

const SearchBar = ({ filters, setFilters, doSearch, options = {} }) => {
  const intl = useIntl();

  return (
    <div className="search-bar rounded bg-light py-2 px-4 mb-4 mr-lg-4 ml-lg-4">
      <div className="d-flex search-container align-items-center justify-content-center flex-wrap">
        <TextFilter
          id="searchableText"
          value={filters['searchableText'] ?? ''}
          placeholder={intl.formatMessage(messages.search_keyword)}
          onChange={(id, value) => {
            setFilters({
              ...filters,
              [id]: value,
              searchableLetter: null,
            });
          }}
        />

        <div className="mr-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
          <SelectInput
            id="sede"
            value={filters['sede'] ?? ''}
            placeholder={intl.formatMessage(messages.sede)}
            onChange={(opt) => {
              setFilters({ ...filters, sede: opt });
            }}
            options={options.sedi ?? []}
            isClearable={true}
            isSearchable={true}
          />
        </div>

        <div className="mr-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
          <SelectInput
            id="dipartimento"
            value={filters['dipartimento'] ?? ''}
            placeholder={intl.formatMessage(messages.dipartimento)}
            onChange={(opt) => {
              setFilters({ ...filters, dipartimento: opt });
            }}
            options={options.dipartimenti ?? []}
            isClearable={true}
            isSearchable={true}
          />
        </div>

        <Button
          color="primary"
          icon={false}
          tag="button"
          onClick={() => {
            doSearch(1, filters);
          }}
          className="my-2 my-lg-1"
          aria-controls="rubrica-results"
        >
          {intl.formatMessage(messages.find)}
        </Button>
      </div>
    </div>
  );
};

export default SearchBar;
