import config from '@plone/volto/registry';

export const GET_RUBRICA = 'GET_RUBRICA';

/*
getRubrica fn:
data = {q:'searchable text', dipartimento:'uid dipartimento', sede:'uid sede', b_size:20}
*/

export function getRubrica(data = {}, page = 0) {
  return {
    type: GET_RUBRICA,
    request: {
      op: 'post',
      path: `/@rubrica`,
      data: {
        ...data,
        ...(!data.b_size && {
          b_size: config.settings.defaultPageSize,
        }),
        ...(page && {
          b_start: data.b_size
            ? data.b_size * (page - 1)
            : config.settings.defaultPageSize * (page - 1),
        }),
      },
    },
  };
}
