import React from 'react';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'design-react-kit/dist/design-react-kit';

const Skeleton = () => {
  return (
    <div className="skeleton-template">
      <Row>
        <Col>
          <Card>
            <CardBody className="pb-2">
              <CardTitle tag="h2">-</CardTitle>
              <CardText></CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Skeleton;
