import React from 'react';
import logo from '@package/components/layout/images/Logo.svg';

const LogoFooter = () => (
  <figure className="icon">
    <img
      src={logo}
      width="75"
      height="75"
      alt="Servizio Sanitario Regione Emilia-Romagna Logo"
    />
  </figure>
);

export default LogoFooter;
