import { Pagination } from '@italia/components/ItaliaTheme';
import { getQueryStringResults } from '@plone/volto/actions';
import cx from 'classnames';
import { Spinner } from 'design-react-kit/dist/design-react-kit';
import { createRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

import SearchBar from './SearchBar';
// import SearchSorting from './SearchSorting';
import { getAddressTree } from '@package/actions';
import MapResults from './MapResults';
import Results from './Results';
import './styles.scss';

const Body = (props) => {
  const { id, isEditMode, data, path } = props;
  const dispatch = useDispatch();
  const resultsRef = createRef();
  const b_size = 10; // number of results per page to show
  const [currentPage, setCurrentPage] = useState(1);
  const searchPath = data?.location?.[0]?.['@id'] || path;

  const querystringResults = useSelector((state) => {
    return state.querystringsearch?.subrequests?.[id + '_ambulatori_search'];
  });
  const items = useSelector((state) => {
    return (
      state.querystringsearch?.subrequests?.[id + '_ambulatori_search']
        ?.items ?? []
    );
  });

  const loading = useSelector((state) => {
    return (
      state.querystringsearch?.subrequests?.[id + '_ambulatori_search']
        ?.loading || false
    );
  });

  // TODO: attualmente non modificabile
  // TODO: prenderlo/scriverlo (almeno come valore inziiale) dalla query nelle properities/data
  // const [sorting, setSorting] = useState({
  //   sort_on: 'parent_sortable_title',
  //   sort_order: 'ascending',
  // });
  const sorting = {
    sort_on: 'parent_sortable_title',
    sort_order: 'ascending',
  };

  const [filters, setFilters] = useState({});
  const subsite = useSelector((state) => state.subsite?.data);

  // const cityVocabulary = useSelector(
  //   (state) =>
  //     state.vocabularies?.[
  //       `${data.location[0]['@id']}/@vocabularies/iosanita.contentypes.vocabularies.city`
  //     ]?.items ?? [],
  //   shallowEqual,
  // );

  // const circoscrizioneVocabulary = useSelector(
  //   (state) =>
  //     state.vocabularies?.['iosanita.contentypes.vocabularies.circoscrizione']
  //       ?.items ?? [],
  //   shallowEqual,
  // );

  const addressTree = useSelector((state) => state.addressTree);

  const circoscrizioneVocabulary =
    addressTree.loaded && !addressTree.hasError
      ? addressTree.result.items.map((elem) => {
          return { token: elem.token, label: elem.label };
        })
      : [];

  const cityVocabulary = useMemo(
    () =>
      addressTree.loaded && !addressTree.hasError
        ? addressTree?.result?.items
            .filter(
              (elem) =>
                !filters.circoscrizione ||
                filters.circoscrizione.token === elem.token,
            )
            .map((elem) => elem.childrens)
            .flat()
            .sort((a, b) => a.label > b.label)
        : [],
    [addressTree, filters.circoscrizione],
  );

  useEffect(() => {
    // dispatch(
    //   getVocabulary({
    //     vocabNameOrURL: `${data.location[0]['@id']}/@vocabularies/iosanita.contentypes.vocabularies.city`,
    //     size: 100,
    //   }),
    // );
    // dispatch(
    //   getVocabulary({
    //     vocabNameOrURL: 'iosanita.contentypes.vocabularies.circoscrizione',
    //     size: 100,
    //   }),
    // );
    dispatch(getAddressTree(flattenToAppURL(searchPath)));
  }, [dispatch, searchPath]);

  const doRequest = ({ page }) => {
    const query = (data?.query?.query ?? []).filter(
      (elem) => !['portal_type', 'path'].includes(elem.i),
    );
    query.push({
      i: 'portal_type',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['Venue'],
    });
    query.push({
      i: 'path',
      o: 'plone.app.querystring.operation.string.absolutePath',
      v: flattenToAppURL(searchPath),
    });

    if (filters?.['parent_title']) {
      query.push({
        i: 'parent_title',
        o: 'plone.app.querystring.operation.string.contains',
        v: `${filters['parent_title']}*`,
      });
    }

    const addressComponents = [
      ['city', 'C'],
      ['circoscrizione', 'D'],
    ]
      // XXX: vengono usate le label anzichè i valori per evitare problemi con i caratteri speciali
      .filter(([key, prefix]) => filters?.[key]?.label)
      .map(([key, prefix]) => `${prefix}#${filters[key].label}`);

    if (addressComponents.length > 0) {
      query.push({
        i: 'address_components',
        o: 'plone.app.querystring.operation.selection.all',
        v: addressComponents,
      });
    }

    dispatch(
      getQueryStringResults(
        subsite ? flattenToAppURL(subsite['@id']) : '',
        {
          fullobjects: 1, // ??
          query: query,
          b_size: b_size,
          sort_on: sorting.sort_on ?? 'parent_sortable_title',
          sort_order: sorting.sort_order ?? 'ascending',
        },
        id + '_ambulatori_search',
        page,
      ),
    );
  };

  function handleQueryPaginationChange(e, { activePage }) {
    resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    const current = activePage?.children ?? 1;
    setCurrentPage(current);
    doRequest({ page: current });
  }

  useEffect(() => {
    doRequest({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cx('block search-ambulatori', {
        'public-ui': isEditMode,
        'full-width': false, // TODO: add option ?
      })}
    >
      <SearchBar
        isEditMode={isEditMode}
        setFilters={setFilters}
        filters={filters}
        options={{
          city: cityVocabulary,
          circoscrizione: circoscrizioneVocabulary,
        }}
        doRequest={() => {
          setCurrentPage(1);
          doRequest({ page: 1 });
        }}
      />
      {/* <SearchSorting
          setSorting={setSorting}
          sorting={sorting}
          isEditMode={isEditMode}
        /> */}

      {!loading ? (
        <>
          <Results items={items} isEditMode={isEditMode} resRef={resultsRef} />
          {querystringResults && querystringResults.total > b_size && (
            <Pagination
              activePage={currentPage}
              totalPages={Math.ceil(querystringResults.total / b_size)}
              onPageChange={handleQueryPaginationChange}
            />
          )}
          {/* TODO: mappa */}
          {data?.show_map && <MapResults items={items} />}
        </>
      ) : (
        <div className="d-flex justify-content-center mt-3">
          <Spinner active />
        </div>
      )}
    </div>
  );
};

export default Body;
