import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { defineMessages, useIntl } from 'react-intl';
import {
  ArrayWidget,
  CheckboxWidget,
  TextWidget,
} from '@plone/volto/components';
import { LinkToWidget } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { ps_list } from './utils';

const messages = defineMessages({
  ps: {
    id: 'ps_times_ps',
    defaultMessage: 'Pronto soccorso',
  },
  show_titles: {
    id: 'ps_times_show_titles',
    defaultMessage: 'Mostra i titoli',
  },
  linkMoreTitle: {
    id: 'linkMoreTitle',
    defaultMessage: 'Titolo per il link ad altro',
  },
  linkMore: {
    id: 'linkMore',
    defaultMessage: 'Link ad altro',
  },
});

const Sidebar = ({ block, data, onChangeBlock, openObjectBrowser }) => {
  const intl = useIntl();

  if (data.show_titles === undefined) {
    //di default mette a true la variabile show_titles
    onChangeBlock(block, {
      ...data,
      show_titles: true,
    });
  }
  return (
    <Segment.Group raised key={block.id || block}>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="ps_times"
            defaultMessage="Tempi Pronto Soccorso"
          />
        </h2>
      </header>
      <Segment>
        <div className="ui form">
          <ArrayWidget
            id="ps"
            title={intl.formatMessage(messages.ps)}
            value={data.ps}
            onChange={(id, value) => {
              onChangeBlock(block, {
                ...data,
                [id]: value,
              });
            }}
            choices={ps_list}
          />
          <CheckboxWidget
            id="show_titles"
            default={true}
            title={intl.formatMessage(messages.show_titles)}
            value={data.show_titles}
            onChange={(id, value) => {
              onChangeBlock(block, {
                ...data,
                [id]: value,
              });
            }}
          />

          <TextWidget
            id="linkMoreTitle"
            title={intl.formatMessage(messages.linkMoreTitle)}
            value={data.linkMoreTitle}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                linkMoreTitle: value,
              });
            }}
          />

          <LinkToWidget
            data={data}
            openObjectBrowser={openObjectBrowser}
            title={intl.formatMessage(messages.linkMore)}
            showTarget={false}
            onChange={(name, value) =>
              onChangeBlock(block, {
                ...data,
                [name]: value,
              })
            }
          />
        </div>
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  block: PropTypes.string,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
};

export default injectIntl(Sidebar);
