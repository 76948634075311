import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { Table, Spinner } from 'design-react-kit/dist/design-react-kit';
import { getPSStatus } from '@package/actions';
import { FontAwesomeIcon } from '@italia/components/ItaliaTheme';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';

import './ps_times.scss';

const messages = defineMessages({
  triage_code: { id: 'ps_triage_code', defaultMessage: 'Codice triage' },
  total: { id: 'ps_total', defaultMessage: 'Totali presenti' },
  to_visit: { id: 'ps_to_visit', defaultMessage: 'Da visitare' },
  waiting_avg: { id: 'ps_waiting_avg', defaultMessage: 'Attesa media' },
  waiting_help: {
    id: 'ps_waiting_help',
    defaultMessage:
      'I tempi di attesa per la visita sono calcolati in base alla media delle ultime 24 ore.',
  },
});

const Body = ({ data, block, isEditMode, id }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const configured_ps = data.ps ?? [];
  const subrequest = block ?? id;

  const ps_status_fetch = useSelector(
    (state) => state.PSStatus?.subrequests?.[subrequest] ?? {},
  );

  const ps_status_list = useSelector(
    (state) => state.PSStatus?.subrequests?.[subrequest]?.result,
  );

  useEffect(() => {
    const loaded = isEditMode ? false : ps_status_fetch.loaded;
    if (!ps_status_fetch.loading && !loaded) {
      dispatch(getPSStatus(configured_ps, subrequest));
    }
  }, [data.ps]);

  const CODES = [
    { code: 'R', name: 'Rosso' },
    { code: 'A', name: 'Arancione' },
    { code: 'Z', name: 'Azzurro' },
    { code: 'V', name: 'Verde' },
    { code: 'B', name: 'Bianco' },
  ];

  return (
    <div className="public-ui">
      {isEditMode && configured_ps?.length === 0 && (
        <div className="info text-center">
          Selezionare almeno un pronto soccorso dalla barra a lato
        </div>
      )}
      {ps_status_fetch.loading ? (
        <div className="d-flex justify-content-center mt-3">
          <Spinner active />
        </div>
      ) : ps_status_list?.length > 0 ? (
        <div className="ps-times">
          {ps_status_list
            .sort(
              (a, b) =>
                configured_ps.indexOf(a.id) - configured_ps.indexOf(b.id),
            )
            .map((ps) => (
              <div className="ps_status_wrapper mb-5">
                {data.show_titles && <h2>{ps.name}</h2>}
                <Table striped className="mt-2" size="sm">
                  <thead>
                    <tr>
                      <th scope="col">
                        {intl.formatMessage(messages.triage_code)}
                      </th>
                      <th scope="col" className="text-center">
                        {intl.formatMessage(messages.total)}
                      </th>
                      <th scope="col" className="text-center">
                        {intl.formatMessage(messages.to_visit)}
                      </th>
                      <th scope="col" className="text-center">
                        {intl.formatMessage(messages.waiting_avg)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {CODES.map((code) => {
                      const total =
                        ps.encountersStatus.opening?.filter(
                          (o) => o.code === code.code,
                        )?.[0]?.count ?? 0;
                      const waiting =
                        ps.encountersStatus.openingWait?.filter(
                          (o) => o.code === code.code,
                        )?.[0]?.count ?? 0;

                      let waiting_avg =
                        ps.encountersStatus.openingWait?.filter(
                          (o) => o.code === code.code,
                        )?.[0]?.averageTime ?? 0;

                      // waiting_avg =
                      //   waiting_avg > 0 ? (waiting_avg / 60).toFixed() : 0;

                      return (
                        <tr>
                          <th scope="row">
                            <div className={'triage-code ' + code.code}></div>
                            {code.name}
                          </th>
                          <td className="text-center">{total}</td>
                          <td className="text-center">{waiting}</td>
                          <td className="text-center">{waiting_avg} min</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={4}>
                        <div className="footer-infos">
                          <FontAwesomeIcon
                            icon={['fas', 'exclamation-triangle']}
                          />

                          {intl.formatMessage(messages.waiting_help)}
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            ))}

          {data.href && data.linkMoreTitle && (
            <div className="link-button text-center my-4">
              <UniversalLink
                href={flattenToAppURL(data.href)}
                className="btn btn-tertiary"
              >
                {data.linkMoreTitle}
              </UniversalLink>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Body;
