import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { OSMMap } from 'volto-venue';
// import { flattenToAppURL } from '@plone/volto/helpers';

const MapResults = ({ items }) => {
  // const history = useHistory();
  const markers = useMemo(
    () =>
      items
        .filter(
          (item) =>
            item.geolocation?.latitude &&
            item.geolocation?.longitude &&
            item.geolocation?.latitude !== 0 &&
            item.geolocation?.longitude !== 0,
        )
        .map((item) => {
          const title = `${item.parent?.title} - ${item.street} ${item.zip_code} ${item.city}`;
          //  (${item.provincia})`
          return {
            title: title,
            popupContent: (
              <div>
                <h5>
                  <a href={item.parent['@id']}>{item.parent?.title}</a>
                </h5>
                <p>{item.street}</p>
                <p>open googlmap</p>
              </div>
            ),
            // onMarkerClick: (e) => {
            //   history.push(flattenToAppURL(item['@id']));
            // },
            ...item.geolocation,
          };
        }),
    [items],
  );

  const [formData, setFormData] = useState({});
  const doSearch = async () => {
    // According to the api reference, let's try to use format like
    // "380 New York St, Redlands, CA 92373"
    const searchAddress = [
      formData?.street,
      formData?.city,
      formData?.country?.title,
      formData?.zip_code,
    ]
      .filter(Boolean)
      .join(', ');

    try {
      const response = await fetch(
        `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=json&singleLine=${searchAddress}&outFields=Match_addr,Addr_type`,
      );
      const data = await response.json();
      const result = data?.candidates[0];
      console.log(result);
      // setGeolocation({
      //   latitude: result.location.y,
      //   longitude: result.location.x,
      // });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    __CLIENT__ &&
    markers?.length > 0 && (
      <div id="map-results">
        {/* <pre>{JSON.stringify(markers, null, 2)}</pre> */}
        <OSMMap
          markers={markers}
          // showTooltip
          showPopup
          zoom={5}
          cluster={true}
          mapOptions={{
            scrollWheelZoom: false,
            // tap: false,
            // dragging: false,
          }}
        />
      </div>
    )
  );
};

MapResults.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default MapResults;
