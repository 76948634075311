/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { OSMMap } from 'volto-venue';
import { flattenToAppURL } from '@plone/volto/helpers';

const MapResults = ({ items }) => {
  let history = useHistory();

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (items?.length > 0) {
      let points = items
        .filter(
          (item) =>
            item?.latitudine &&
            item?.longitudine &&
            item?.latitudine !== 0 &&
            item?.longitudine !== 0,
        )
        .map((item) => {
          return {
            title:
              item.title +
              ' - ' +
              item.street +
              ' ' +
              item.zip_code +
              ' ' +
              item.comune +
              ' (' +
              item.provincia +
              ')',
            ...(item.geolocation
              ? item.geolocation
              : { latitude: item.latitudine, longitude: item.longitudine }),

            onMarkerClick: (e) => {
              history.push(flattenToAppURL(item['@id']));
            },
          };
        });

      setMarkers(points);
    } else {
      setMarkers([]);
    }
  }, [items]);

  return (
    __CLIENT__ &&
    markers?.length > 0 && (
      <div id="farmacie-map-results">
        <OSMMap
          markers={markers}
          showTooltip
          zoom={5}
          mapOptions={{
            scrollWheelZoom: false,
            // tap: false,
            // dragging: false,
          }}
        />
      </div>
    )
  );
};

MapResults.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default MapResults;
