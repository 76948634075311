/*CUSTOMIZATIONS:
- aggiunta la visualizzazione del cellulare di servizio*/

import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';
import { ContactLink } from '@italia/components/ItaliaTheme/View';

/**
 * PersonaTelephones component class.
 * @function PersonaTelephones
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  telefono: {
    id: 'telefono',
    defaultMessage: 'Tel',
  },
  cellulare_servizio: {
    id: 'cellulare_servizio',
    defaultMessage: 'Cell. di servizio',
  },
});

const PersonaTelephones = ({ content }) => {
  const intl = useIntl();
  const nItems = content?.telefono?.length;

  return nItems > 0 || content.cellulare_servizio ? (
    <p className="d-flex">
      <strong className="mr-1">{intl.formatMessage(messages.telefono)}:</strong>
      <span>
        {content.telefono.map((tel, index) => (
          <>
            <ContactLink tel={tel} label={false} />
            {index !== nItems - 1 && ', '}
          </>
        ))}
        {content.cellulare_servizio && (
          <span>
            {content.telefono?.length > 0 && <br />}
            {intl.formatMessage(messages.cellulare_servizio)}:{' '}
            <ContactLink tel={content.cellulare_servizio} label={false} />
          </span>
        )}
      </span>
    </p>
  ) : null;
};

export default PersonaTelephones;

PersonaTelephones.propTypes = {
  content: PropTypes.object,
};
