import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  richTextHasContent,
  RichText,
  ContactLink,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  riferimento_telefonico_luogo: {
    id: 'riferimento_telefonico_luogo',
    defaultMessage: 'Telefono',
  },
  riferimento_fax_luogo: {
    id: 'riferimento_fax_luogo',
    defaultMessage: 'Fax',
  },
  riferimento_mail_luogo: {
    id: 'riferimento_mail_luogo',
    defaultMessage: 'E-mail',
  },
  riferimento_pec_luogo: {
    id: 'riferimento_pec_luogo',
    defaultMessage: 'PEC',
  },
  riferimento_web: {
    id: 'riferimento_web',
    defaultMessage: 'Web',
  },
});

// import VenueContacts from 'design-comuni-plone-theme/components/ItaliaTheme/View/VenueView/VenueContacts';
// versione di VenueContacts compatta
const VenueContacts = ({ content }) => {
  const intl = useIntl();

  return (
    (content?.telefono ||
      content?.email ||
      content?.fax ||
      content?.pec ||
      content?.web ||
      richTextHasContent(content?.telefono_notes)) && (
      <>
        <h5>Contatti</h5>
        {content.telefono && (
          <p className="card-text mt-3">
            {intl.formatMessage(messages.riferimento_telefonico_luogo)}
            : <ContactLink tel={content.telefono} label={false} />
          </p>
        )}
        {richTextHasContent(content.telefono_notes) && (
          <p className="card-text mt-3">
            <RichText content={content.telefono_notes} />
          </p>
        )}
        {content.fax && (
          <p className="card-text mt-3">
            {intl.formatMessage(messages.riferimento_fax_luogo)}
            : <ContactLink fax={content.fax} label={false} />
          </p>
        )}
        {content.email && (
          <p className="card-text mt-3">
            {intl.formatMessage(messages.riferimento_mail_luogo)}:{' '}
            <ContactLink email={content.email} label={false} />
          </p>
        )}
        {content.pec && (
          <p className="card-text mt-3">
            {intl.formatMessage(messages.riferimento_pec_luogo)}:{' '}
            <ContactLink email={content.pec} label={false} />
          </p>
        )}
        {content.web && (
          <p className="card-text mt-3">
            {intl.formatMessage(messages.riferimento_web)}:{' '}
            <a
              href={
                content.web.match(/^(http:\/\/|https:\/\/)/gm)
                  ? content.web
                  : `http://${content.web}`
              }
            >
              {content.web}
            </a>
          </p>
        )}
      </>
    )
  );
};

export default VenueContacts;
