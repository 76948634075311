/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { defineMessages, useIntl } from 'react-intl';
import {
  SelectWidget,
  CheckboxWidget,
  ArrayWidget,
} from '@plone/volto/components';
import { getVocabulary } from '@plone/volto/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

const messages = defineMessages({
  search_type_help: {
    id: 'search_type_help',
    defaultMessage: 'Seleziona il tipo di ricerca da effettuare',
  },
  search_type: {
    id: 'search_type',
    defaultMessage: 'Ricerca per',
  },
  search_shifts: {
    id: 'search_shifts',
    defaultMessage: 'Turni',
  },
  search_vacations: {
    id: 'search_vacations',
    defaultMessage: 'Ferie',
  },
  search_show_map: {
    id: 'search_show_map',
    defaultMessage: 'Mostra la mappa',
  },
  search_area_territoriale: {
    id: 'search_area_territoriale',
    defaultMessage: 'Aree territoriali predefinite',
  },
});

const vocabulary = 'auslfe.farmacie.aree_territoriali';

const Sidebar = ({ block, data, onChangeBlock }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const types = [
    ['shifts', intl.formatMessage(messages.search_shifts)],
    ['vacations', intl.formatMessage(messages.search_vacations)],
  ];

  const aree_territoriali = useSelector(
    (state) =>
      state.vocabularies[vocabulary] && state.vocabularies[vocabulary].items
        ? state.vocabularies[vocabulary].items
        : [],
    shallowEqual,
  );

  /* set default searchType */
  // useEffect(() => {
  //   onChangeBlock(block, {
  //     ...data,
  //     search_type:
  //       data.search_type === undefined ? types[0][0] : data.search_type,
  //   });
  // }, []);

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: vocabulary,
      }),
    );
  }, [dispatch]);

  return (
    <Segment.Group raised key={block.id || block}>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="searchFarmacia"
            defaultMessage="Ricerca farmacie"
          />
        </h2>
      </header>
      <Segment>
        <div className="ui form">
          <p className="help">
            {intl.formatMessage(messages.search_type_help)}
          </p>
          <SelectWidget
            id="search_type"
            title={intl.formatMessage(messages.search_type)}
            value={data.search_type}
            onChange={(id, value) => {
              onChangeBlock(block, {
                ...data,
                search_type: value,
              });
            }}
            choices={types}
          />

          <br />
          <ArrayWidget
            id="search_area_territoriale"
            title={intl.formatMessage(messages.search_area_territoriale)}
            value={data.search_area_territoriale}
            onChange={(id, value) => {
              onChangeBlock(block, {
                ...data,
                search_area_territoriale: value,
              });
            }}
            choices={aree_territoriali}
          />
          <CheckboxWidget
            id="show_map"
            title={intl.formatMessage(messages.search_show_map)}
            value={data.show_map}
            onChange={(name, checked) => {
              onChangeBlock(block, { ...data, show_map: checked });
            }}
          />
        </div>
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  block: PropTypes.string,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
};

export default injectIntl(Sidebar);
