/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createRef, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'design-react-kit/dist/design-react-kit';
import { Pagination } from '@italia/components/ItaliaTheme';
import SearchBar from './SearchBar';
import AlphabeticalSearch from './AlphabeticalSearch';
import Results from './Results';
import { getRubrica } from '@package/actions';

const Body = ({ isEditMode, data }) => {
  const dispatch = useDispatch();
  const b_size = 20;
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const resultsRef = createRef();

  const loading = useSelector((state) => {
    return state.rubrica?.loading || false;
  });
  const rubrica = useSelector((state) => {
    return state.rubrica?.result;
  });

  const doSearch = (page = currentPage, filters) => {
    let query = {};
    delete query.searchableText;
    if (filters.searchableText?.length > 0) {
      query.q = filters.searchableText + '*';
    }
    if (filters.dipartimento) {
      query.dipartimento = filters.dipartimento.value;
    }
    if (filters.sede) {
      query.sede = filters.sede.value;
    }
    if (filters.searchableLetter) {
      query.q = filters.searchableLetter + '*';
    }

    dispatch(getRubrica({ ...query, b_size }, page));
  };

  const doDebouncedSearch = useCallback(debounce(doSearch, 400), []);

  useEffect(() => {
    doDebouncedSearch(1, filters);
  }, [filters]);

  function onPaginationChange(e, { activePage }) {
    resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    const current = activePage?.children ?? 1;
    setCurrentPage(current);
    doSearch(current, filters);
  }

  return (
    <div
      className={cx('', {
        'public-ui': isEditMode,
      })}
    >
      <div className="block-rubrica">
        <SearchBar
          isEditMode={isEditMode}
          filters={filters}
          setFilters={setFilters}
          doSearch={doSearch}
          options={rubrica.filters}
        />
        <AlphabeticalSearch
          filters={filters}
          setFilters={setFilters}
          doSearch={doSearch}
        />
        {!loading ? (
          <>
            <Results
              items={rubrica.items}
              isEditMode={isEditMode}
              resRef={resultsRef}
            />
            {/* Paginazione */}
            {rubrica.items_total > b_size && (
              <div className="pagination-wrapper">
                <Pagination
                  activePage={currentPage}
                  totalPages={Math.ceil(rubrica.items_total / b_size)}
                  onPageChange={onPaginationChange}
                />
              </div>
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center mt-3">
            <Spinner active />
          </div>
        )}
      </div>
    </div>
  );
};

export default Body;
