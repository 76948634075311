export const GET_ADDRESS_TREE = 'GET_ADDRESS_TREE';

export function getAddressTree(path) {
  return {
    type: GET_ADDRESS_TREE,
    request: {
      op: 'get',
      // TODO: usare utils
      path: `${path}/@address-components-tree`,
      data: {},
    },
  };
}
