import config from '@plone/volto/registry';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  searchBlock: {
    id: 'searchAmbulatorio',
    defaultMessage: 'Ricerca ambulatorio',
  },
  controls: {
    id: 'Controls',
    defaultMessage: 'Controls',
  },
  baseSearchQuery: {
    id: 'Base search query',
    defaultMessage: 'Base search query',
  },
  sectionTitle: {
    id: 'Section title',
    defaultMessage: 'Section title',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
  searchInputPrompt: {
    id: 'Search input label',
    defaultMessage: 'Search input label',
  },
  showSearchInput: {
    id: 'Show search input?',
    defaultMessage: 'Show search input?',
  },
  showSearchButtonTitle: {
    id: 'Show search button?',
    defaultMessage: 'Show search button?',
  },
  showSearchButtonDescription: {
    id: 'The button presence disables the live search, the query is issued when you press ENTER',
    defaultMessage:
      'The button presence disables the live search, the query is issued when you press ENTER',
  },
  searchButtonLabel: {
    id: 'Search button label',
    defaultMessage: 'Search button label',
  },
  searchButtonPlaceholder: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  showSortOn: {
    id: 'Show sorting?',
    defaultMessage: 'Show sorting?',
  },
  sortOnOptions: {
    id: 'Sort on options',
    defaultMessage: 'Sort on options',
  },
  facets: {
    id: 'Facets',
    defaultMessage: 'Facets',
  },
  facet: {
    id: 'Facet',
    defaultMessage: 'Facet',
  },
  label: {
    id: 'Label',
    defaultMessage: 'Label',
  },
  field: {
    id: 'Field',
    defaultMessage: 'Field',
  },
  multipleChoices: {
    id: 'Multiple choices?',
    defaultMessage: 'Multiple choices?',
  },
  hideFacetTitle: {
    id: 'Hide facet?',
    defaultMessage: 'Hide facet?',
  },
  hideFacetDescription: {
    id: 'Hidden facets will still filter the results if proper parameters are passed in URLs',
    defaultMessage:
      'Hidden facets will still filter the results if proper parameters are passed in URLs',
  },
  advancedFacetTitle: {
    id: 'Advanced facet?',
    defaultMessage: 'Advanced facet?',
  },
  advancedFacetDescription: {
    id: 'Advanced facets are initially hidden and displayed on demand',
    defaultMessage:
      'Advanced facets are initially hidden and displayed on demand',
  },
  facetWidget: {
    id: 'Facet widget',
    defaultMessage: 'Facet widget',
  },
  views: {
    id: 'views',
    defaultMessage: 'Views',
  },
  availableViews: {
    id: 'availableViews',
    defaultMessage: 'Available views',
  },
  showTotalResults: {
    id: 'Show total results',
    defaultMessage: 'Show total results',
  },
});

const SearchSchema = ({ data = {}, intl }) => {
  return {
    title: intl.formatMessage(messages.searchBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['location'],
        // fields: ['headline'],
      },
      {
        id: 'searchquery',
        title: intl.formatMessage(messages.baseSearchQuery),
        fields: ['query'],
      },
    ],
    properties: {
      location: {
        title: 'Location',
        widget: 'object_browser',
      },
      query: {
        title: 'Query',
        widget: 'query',
      },
    },
    required: [],
  };
};

export default SearchSchema;
