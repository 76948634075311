import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { SelectInput } from '@italia/components';
import { TextFilter } from '@italia/components/ItaliaTheme/Blocks/Common/SearchFilters';
import { DatetimeWidget } from '@plone/volto/components';

const messages = defineMessages({
  search_keyword: {
    id: 'farmacia_search_keyword',
    defaultMessage: 'Cerca per parola chiave',
  },
  area_territoriale: {
    id: 'farmacia_area_territoriale',
    defaultMessage: 'Area territoriale',
  },
  date: {
    id: 'farmacia_data',
    defaultMessage: 'Seleziona il giorno',
  },
  comune: {
    id: 'farmacia_comune',
    defaultMessage: 'Comune',
  },
  localita: {
    id: 'farmacia_localita',
    defaultMessage: 'Località',
  },
  find: {
    id: 'find',
    defaultMessage: 'Cerca',
  },
});

const SearchBar = ({
  searchType,
  filters,
  setFilters,
  options,
  checkClearComune,
}) => {
  const intl = useIntl();

  return (
    <div className="search-bar rounded bg-light py-2 px-4 mr-lg-4 ml-lg-4">
      <div className="d-flex search-container align-items-center justify-content-center flex-wrap">
        <TextFilter
          id="searchableText"
          value={filters['searchableText'] ?? ''}
          placeholder={intl.formatMessage(messages.search_keyword)}
          onChange={(id, value) => {
            setFilters({
              ...filters,
              [id]: value,
            });
          }}
        />

        {/* Turni - Data widget */}
        {searchType === 'shifts' && (
          <div className="ml-lg-1 mr-lg-2 my-2 my-lg-1 filter-wrapper select-filter">
            <DatetimeWidget
              id="date_farmacie"
              dateOnly={true}
              resettable={false}
              value={filters['date']}
              onChange={(opt, value) => {
                setFilters({
                  ...filters,
                  date: new Date(value).toISOString(),
                });
              }}
            />
          </div>
        )}

        {/* Select area_territoriale */}
        {searchType !== 'vacations' && (
          <div className="my-2 my-lg-1 mr-lg-3 filter-wrapper select-filter">
            <SelectInput
              id="area_territoriale"
              value={filters['area_territoriale'] ?? ''}
              placeholder={intl.formatMessage(messages.area_territoriale)}
              onChange={(opt) => {
                setFilters({ ...filters, area_territoriale: opt });
              }}
              options={options.aree_territoriali ?? []}
              isClearable={true}
              isSearchable={true}
            />
          </div>
        )}

        {/* Select comuni */}
        <div className="my-2 my-lg-1 filter-wrapper select-filter">
          <SelectInput
            id="comune"
            value={filters['comune'] ?? ''}
            placeholder={intl.formatMessage(messages.comune)}
            onChange={(opt) => {
              setFilters({ ...filters, comune: opt });
              checkClearComune(opt);
            }}
            options={options.comuni ?? []}
            isClearable={true}
            isSearchable={true}
          />
        </div>

        {/* Ferie - Select località */}
        {searchType === 'vacations' && (
          <div className="ml-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
            <SelectInput
              id="localita"
              value={filters['localita'] ?? ''}
              placeholder={intl.formatMessage(messages.localita)}
              onChange={(opt) => {
                setFilters({ ...filters, localita: opt });
              }}
              options={options.lista_localita ?? []}
              isClearable={true}
              isSearchable={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
