import React from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import Body from './Body.jsx';
import Schema from './schema';

const Edit = (props) => {
  const { data, selected, onChangeBlock, block, intl } = props;
  const schema = Schema({ data, intl });
  return (
    <>
      <Body {...props} isEditMode={true} />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          onChangeBlock={onChangeBlock}
          formData={data}
        />
      </SidebarPortal>
    </>
  );
};

export default Edit;
