import React from 'react';
import logo from '@package/components/layout/images/Logo.svg';

const Logo = () => (
  <figure className="icon">
    <img
      src={logo}
      width="78"
      height="78"
      alt="Servizio Sanitario Regione Emilia-Romagna Logo"
    />
  </figure>
);

export default Logo;
