import React from 'react';
import PropTypes from 'prop-types';
import { RichTextRender, richTextHasContent } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
    Card,
    CardBody,
    CardTitle,
    CardText,
} from 'design-react-kit/dist/design-react-kit';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

// import VenuePublicTimetable from 'design-comuni-plone-theme/components/ItaliaTheme/View/VenueView/VenuePublicTimetable';

const VenuePublicTimetable = ({ content }) => {

    const content_to_display = richTextHasContent(content.orario_pubblico) && RichTextRender({
        content: content.orario_pubblico,
        // add_class: add_class,
        serif: true,
    });

    return content_to_display ? (<>
        <h5>Orari</h5>
        {content_to_display}
    </>
    ) : <></>
};
export default VenuePublicTimetable;

