import React from 'react';
import Body from './Body.jsx';

const View = (props) => {
  return (
    <>
      <Body {...props} />
    </>
  );
};

export default View;
