import React from 'react';
import VenueDescription from 'design-comuni-plone-theme/components/ItaliaTheme/View/VenueView/VenueDescription';
import {
    RichTextArticle,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
    Card,
    CardBody,
} from 'design-react-kit/dist/design-react-kit';
import VenueWhere from './VenueWhere';
import VenueContacts from './VenueContacts';
import VenuePublicTimetable from './VenuePublicTimetable';


const PersonaAmbulatori = ({ content }) => {

    return (
        <>
            {/* XXX:
                al momento per capire se è un fullobject o un brain si guarda se ha @components valorizzato,
                trovare una logica migliore
            */}
            {content.items.filter((item) => item['@type'] === 'Venue' && item['@components']).map((item, index) => {
                return <RichTextArticle tag_id={`ambulatorio-${index}`} title={item.title}>
                    <Card
                        className="card rounded shadow mt-3 mb-3"
                        noWrapper={true}
                        tag="div">
                        <CardBody tag="div" className={'card-body pr-3'}>
                            <VenueDescription content={item} />
                            <VenueWhere content={item} />
                            <VenueContacts content={item} />
                            <VenuePublicTimetable content={item} />
                        </CardBody>
                    </Card>
                </RichTextArticle>
            }
            )}
        </>
    );
};

export default PersonaAmbulatori;