/**
 * FarmaciaView view component.
 * @module components/theme/View/FarmaciaView
 */

import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  SideMenu,
  PageHeader,
  ContentImage,
  RelatedItems,
  RelatedItemInEvidence,
  SkipToMainContent,
  ContentTypeViewSections,
} from '@italia/components/ItaliaTheme/View';

import {
  FarmaciaWhere,
  FarmaciaShifts,
  FarmaciaVacations,
  FarmaciaContacts,
  FarmaciaMoreInfos,
} from '@package/components/View';

export const FarmaciaViewSectionsOrder = [
  {
    /* HEADER IMAGE */

    component: ContentImage,
    props: { position: 'documentBody' },
  },

  { /* MAPPA */ component: FarmaciaWhere },

  { /* TURNI */ component: FarmaciaShifts },

  { /* FERIE */ component: FarmaciaVacations },

  { /* CONTATTI */ component: FarmaciaContacts },

  { /* ULTERIORI INFORMAZIONI */ component: FarmaciaMoreInfos },
];

/**
 * FarmaciaView view component class.
 * @function FarmaciaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const FarmaciaView = ({ content }) => {
  let documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);

  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setSideMenuElements(documentBody.current);
      }
    }
  }, [documentBody]);

  useEffect(() => {
    if (
      content.nome_alternativo &&
      !content.title?.includes(content.nome_alternativo)
    ) {
      content.subtitle = content.nome_alternativo;
    }
  });

  return (
    <>
      <div className="container px-4 my-4 luogo-view">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={false}
          showdates={false}
          showtassonomiaargomenti={true}
        />
        {/* HEADER IMAGE */}
        <ContentImage content={content} position="afterHeader" />

        <div className="row border-top row-column-border row-column-menu-left">
          <aside className="col-lg-4">
            {__CLIENT__ && (
              <SideMenu data={sideMenuElements} content_uid={content?.UID} />
            )}
          </aside>
          <section
            className="col-lg-8 it-page-sections-container"
            id="main-content-section"
            ref={documentBody}
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={FarmaciaViewSectionsOrder}
            />
          </section>
        </div>
      </div>
      <RelatedItems
        list={[
          ...(content.relatedItems ?? []),
          ...(content.related_news ?? []),
        ]}
      />
      <RelatedItemInEvidence content={content} />
    </>
  );
};
FarmaciaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};
export default FarmaciaView;
