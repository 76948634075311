/* CUSTOMIZATIONS:
- sostituito Locations di DCPT con la card custom di AUSL Romagna per le sedi principali più i singoli Location
- Adesso locations fa vedere anche le sedi secondarie
*/

import React from 'react';
import UOGenericCard from '@package/components/View/UOView/UOGenericCard';
import LocationsMap from 'design-comuni-plone-theme/components/ItaliaTheme/View/Commons/LocationsMap';
import LocationItem from 'design-comuni-plone-theme/components/ItaliaTheme/View/Commons/LocationItem';

const UOContactsLocations = ({ content, loca }) => {
  let location_items = [];
  let locations = [
    ...(content?.sede ?? []),
    ...(content?.sedi_secondarie ?? []),
  ];
  if (
    content?.nome_sede ||
    content?.street ||
    content?.city ||
    content?.zip_code ||
    content?.quartiere ||
    content?.circoscrizione
  ) {
    location_items.push(content);
  }

  location_items = [...location_items, ...locations];

  return content.geolocation?.latitude > 0 ||
    content?.geolocation?.longitude > 0 ||
    content?.sede?.length > 0 ||
    content?.nome_sede?.length > 0 ||
    content?.street?.length > 0 ||
    content?.city?.length > 0 ||
    content?.zip_code?.length > 0 ||
    content?.quartiere?.length > 0 ||
    content?.circoscrizione?.length > 0 ? (
    <div className="mb-5 mt-3">
      <div className="card-wrapper card-teaser-wrapper align-items-stretch">
        {[...location_items].map((item, i) =>
          item['@type'] !== 'Venue' ? (
            <LocationItem
              key={item['@id'] + i}
              location={item}
              show_icon={false}
              show_title_link={false}
              load={true}
              details_link={false}
            />
          ) : (
            <UOGenericCard item={item} />
          ),
        )}
      </div>
      <LocationsMap center={content} locations={locations} />
    </div>
  ) : (
    <></>
  );
};
export default React.memo(UOContactsLocations);
