/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { useIntl, defineMessages } from 'react-intl';
import { FontAwesomeIcon } from '@italia/components/ItaliaTheme';
import { Container, Button } from 'design-react-kit/dist/design-react-kit';

const messages = defineMessages({
  filter_by_letter: {
    id: 'rubrica_filter_by_letter',
    defaultMessage: 'Filtra per lettera ',
  },
  remove_filter_by_letter: {
    id: 'rubrica_remove_filter_by_letter',
    defaultMessage: 'Rimuovi il filtro per lettera ',
  },
});

const LETTERS = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

const AlphabeticalSearch = ({ filters, setFilters, doSearch }) => {
  const intl = useIntl();

  return (
    <Container className="alphabetical-search">
      <ul>
        {LETTERS.map((letter) => (
          <li key={letter}>
            <Button
              onClick={() => {
                setFilters({
                  ...filters,
                  searchableText: null,
                  searchableLetter:
                    filters.searchableLetter === letter ? null : letter,
                });
              }}
              aria-controls="rubrica-results"
              aria-label={
                (filters.searchableLetter === letter
                  ? intl.formatMessage(messages.remove_filter_by_letter)
                  : intl.formatMessage(messages.filter_by_letter)) +
                ' ' +
                letter
              }
              className={filters.searchableLetter === letter ? 'active' : ''}
            >
              {letter}
            </Button>
          </li>
        ))}
      </ul>
      {filters.searchableLetter?.length > 0 && (
        <div className="remove-filter">
          <span
            role="button"
            tabIndex="0"
            onClick={() => {
              setFilters({
                ...filters,
                searchableLetter: null,
              });
            }}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />{' '}
            {intl.formatMessage(messages.remove_filter_by_letter)}
          </span>
        </div>
      )}
    </Container>
  );
};

export default AlphabeticalSearch;
