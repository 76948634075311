import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Table } from 'design-react-kit/dist/design-react-kit';
import { UniversalLink } from '@plone/volto/components';
import { ContactLink } from '@italia/components/ItaliaTheme/View';

const messages = defineMessages({
  nome: {
    id: 'rubrica_table_nome',
    defaultMessage: 'Nome',
  },
  dipartimento: {
    id: 'rubrica_table_dipartimento',
    defaultMessage: 'Dipartimento',
  },
  sede: {
    id: 'rubrica_table_sede',
    defaultMessage: 'Sede',
  },
  email: {
    id: 'rubrica_table_email',
    defaultMessage: 'E-mail',
  },
  telefono: {
    id: 'rubrica_table_telefono',
    defaultMessage: 'Telefono',
  },
  telefono_riservato: {
    id: 'rubrica_table_telefono_riservato',
    defaultMessage: 'Telefono riservato',
  },
  responsabile: {
    id: 'rubrica_responsabile',
    defaultMessage: 'Responsabile',
  },
  noResult: {
    id: 'noResult',
    defaultMessage: 'Nessun risultato trovato',
  },
});

const ContactColumns = ({ isEditMode, contact, item }) => {
  const intl = useIntl();
  let ruoli = [];
  if (contact.responsabile) {
    ruoli.push(intl.formatMessage(messages.responsabile));
  }
  if (item.ruolo) {
    ruoli.push(item.ruolo);
  }
  return (
    <>
      <td className="dipartimento">
        {/* dipartimento */}
        {contact.dipartimento ? (
          <>
            <div className="th d-lg-none">
              {intl.formatMessage(messages.dipartimento)}
            </div>

            <UniversalLink
              item={!isEditMode ? contact.dipartimento : null}
              href={isEditMode ? '#' : null}
            >
              {contact.dipartimento.title}
            </UniversalLink>
            {ruoli.length > 0 && <div className="role">{ruoli.join(', ')}</div>}
          </>
        ) : ruoli?.length > 0 ? (
          <div className="role">{ruoli.join(', ')}</div>
        ) : (
          <>-</>
        )}
      </td>
      <td className="sede">
        {/* sede */}

        <div className="th d-lg-none">{intl.formatMessage(messages.sede)}</div>

        {contact.sede?.city ||
        contact.sede?.zip_code ||
        contact.sede?.street ||
        contact.sede?.title ? (
          <div>
            {contact.sede.street && <p>{contact.sede.street}</p>}
            {(contact.sede.city || contact.sede.zip_code) && (
              <p>
                {contact.sede.zip_code} {contact.sede.city}
              </p>
            )}

            {contact.sede['@id'] && (
              <UniversalLink
                item={!isEditMode ? contact.sede : null}
                href={isEditMode ? '#' : null}
              >
                {contact.sede.title}
              </UniversalLink>
            )}
          </div>
        ) : contact.dipartimento?.city ||
          contact.dipartimento?.zip_code ||
          contact.dipartimento?.street ? (
          <div>
            {contact.dipartimento.street && (
              <p>{contact.dipartimento.street}</p>
            )}
            {(contact.dipartimento.city || contact.dipartimento.zip_code) && (
              <p>
                {contact.dipartimento.zip_code} {contact.dipartimento.city}
              </p>
            )}
          </div>
        ) : (
          <> - </>
        )}
      </td>
      <td className="email">
        {/* email */}
        <div className="th d-lg-none">{intl.formatMessage(messages.email)}</div>
        {contact.email?.length > 0 ? (
          <>
            {contact.email.map((email, i) => (
              <p key={i}>
                <ContactLink email={email} label={false} />
              </p>
            ))}
          </>
        ) : (
          <>-</>
        )}
      </td>
      <td className="telefono">
        {/* telefono */}
        <div className="th d-lg-none">
          {intl.formatMessage(messages.telefono)}
        </div>

        {contact.telefono?.length > 0 ||
        contact.telefono_riservato?.length > 0 ? (
          <>
            {contact.telefono?.length > 0 && (
              <>
                {contact.telefono.map((tel, i) => (
                  <p key={i}>
                    <ContactLink tel={tel} label={false} />{' '}
                  </p>
                ))}
              </>
            )}

            {contact.telefono_riservato?.length > 0 && (
              <div>
                <div className="th">
                  {intl.formatMessage(messages.telefono_riservato)}:
                </div>

                {contact.telefono_riservato.map((tel, i) => (
                  <p key={i}>
                    <ContactLink tel={tel} label={false} />
                  </p>
                ))}
              </div>
            )}
          </>
        ) : (
          <>-</>
        )}
      </td>
    </>
  );
};

const Results = ({ items, isEditMode, resRef }) => {
  const intl = useIntl();

  return (
    <>
      {items?.length > 0 ? (
        <div
          id="rubrica-results"
          className="shadow"
          role="region"
          aria-live="polite"
          ref={resRef}
        >
          <Table>
            <thead>
              <tr className="d-none d-lg-table-row">
                <th>{intl.formatMessage(messages.nome)}</th>
                <th>{intl.formatMessage(messages.dipartimento)}</th>
                <th>{intl.formatMessage(messages.sede)}</th>
                <th>{intl.formatMessage(messages.email)}</th>
                <th>{intl.formatMessage(messages.telefono)}</th>
              </tr>
            </thead>

            {items.map((item, i) => (
              <tbody key={i}>
                {item.contatti.map((contact, ic) => (
                  <tr key={i + '-' + ic}>
                    {ic === 0 && (
                      <td
                        rowSpan={
                          item.contatti?.length > 1
                            ? item.contatti.length
                            : null
                        }
                        className="persona"
                      >
                        {ic === 0 ? (
                          <>
                            {item['@id'] ? (
                              <UniversalLink
                                item={!isEditMode ? item : null}
                                href={isEditMode ? '#' : null}
                              >
                                {item.title}
                              </UniversalLink>
                            ) : (
                              item.title
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </td>
                    )}
                    <ContactColumns
                      isEditMode={isEditMode}
                      contact={item.contatti[ic]}
                      item={item}
                    />
                  </tr>
                ))}
              </tbody>
            ))}
          </Table>
        </div>
      ) : (
        <div className="message px-4">
          {intl.formatMessage(messages.noResult)}
        </div>
      )}
    </>
  );
};

export default Results;
