import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Table } from 'design-react-kit/dist/design-react-kit';
import { UniversalLink } from '@plone/volto/components';
// import { ContactLink } from '@italia/components/ItaliaTheme/View';

const messages = defineMessages({
  nome: {
    id: 'ambulatorio_table_nome',
    defaultMessage: 'Nome',
  },
  comune: {
    id: 'ambulatorio_table_comune',
    defaultMessage: 'Comune',
  },
  distretto: {
    id: 'ambulatorio_table_distretto',
    defaultMessage: 'Distretto',
  },
  indirizzo: {
    id: 'ambulatorio_table_indirizzo',
    defaultMessage: 'Indirizzo',
  },
  recapiti: {
    id: 'ambulatorio_table_recapiti',
    defaultMessage: 'Recapiti',
  },
  telefono: {
    id: 'ambulatorio_table_telefono',
    defaultMessage: 'Telefono',
  },
  noResult: {
    id: 'noResult',
    defaultMessage: 'Nessun risultato trovato',
  },
});

const Results = ({ items, isEditMode, resRef, searchType, dateFilter }) => {
  const intl = useIntl();

  return (
    <>
      {items?.length > 0 ? (
        <div
          id="ambulatori-results"
          className="shadow"
          role="region"
          aria-live="polite"
          ref={resRef}
        >
          <Table>
            <thead>
              <tr className="d-none d-lg-table-row">
                <th>{intl.formatMessage(messages.nome)}</th>
                {/* <th>{intl.formatMessage(messages.comune)}</th> */}
                <th>{intl.formatMessage(messages.distretto)}</th>
                <th>{intl.formatMessage(messages.comune)}</th>
                <th>{intl.formatMessage(messages.indirizzo)}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, i) => (
                <tr key={i}>
                  <td className="nome">
                    <UniversalLink
                      item={!isEditMode ? item.parent : null}
                      href={isEditMode ? '#' : null}
                    >
                      {item.parent.title}
                    </UniversalLink>
                  </td>

                  <td className="circoscrizione">
                    <div className="th d-lg-none">
                      {intl.formatMessage(messages.distretto)}
                    </div>
                    {item.circoscrizione}
                  </td>

                  <td className="comune">
                    <div className="th d-lg-none">
                      {intl.formatMessage(messages.comune)}
                    </div>
                    {item.city}
                  </td>

                  {/* Indirizzo */}
                  <td className="indirizzo">
                    <div className="th d-lg-none">
                      {intl.formatMessage(messages.indirizzo)}
                    </div>
                    {/* TODO: vedere come è creato l'indirzzo in Venue */}
                    {item?.street ||
                    item?.zip_code ||
                    item?.localita ||
                    item?.quartiere ||
                    item?.provincia ? (
                      <div>
                        {item?.street && (
                          <>
                            {item.street} <br />
                          </>
                        )}
                        {item?.zip_code && item.zip_code}
                        <> </>
                        {item?.localita && item.localita}
                        <> </>
                        {item?.quartiere && item.quartiere}
                        <> </>
                        {item?.provincia && <>({item.provincia})</>}
                      </div>
                    ) : (
                      <> - </>
                    )}
                  </td>

                  {/* <td className="recapiti">
                    <div className="th d-lg-none">
                      {intl.formatMessage(messages.recapiti)}
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="message px-4">
          {intl.formatMessage(messages.noResult)}
        </div>
      )}
    </>
  );
};

export default Results;
