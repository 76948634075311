import React from 'react';

import { defineMessages, useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
  CardReadMore,
} from 'design-react-kit/dist/design-react-kit';

const messages = defineMessages({
  orari_telefoni: {
    id: 'uo_orari_telefoni',
    defaultMessage: 'Orari e telefoni',
  },
});
const UOGenericCard = ({ item }) => {
  const intl = useIntl();

  return item ? (
    <Card
      noWrapper={true}
      tag="div"
      className="genericcard card-teaser shadow mt-3"
    >
      <CardBody>
        <CardTitle tag="h5">
          <UniversalLink item={item}>{item.title}</UniversalLink>
        </CardTitle>

        {item.description && (
          <CardText tag="p" className="mb-2">
            {item.description}
          </CardText>
        )}
        <CardReadMore
          iconName="it-arrow-right"
          tag={UniversalLink}
          text={intl.formatMessage(messages.orari_telefoni)}
          href={flattenToAppURL(item['@id'])}
        />
      </CardBody>
    </Card>
  ) : (
    <></>
  );
};

export default UOGenericCard;
