import React from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Chip, ChipLabel } from 'design-react-kit/dist/design-react-kit';
import { Link } from 'react-router-dom';

const UOPageHeader = ({ content }) => {
  return content ? (
    <>
      {/* INFO DELL'ASSESSORE DI RIFERIMENTO */}
      {content?.assessore_riferimento?.length > 0 && (
        <div className="ruolo-persone-assessore">
          {content.assessore_riferimento.map((item, _i) => (
            <>
              {content?.assessore_riferimento_role?.title ? (
                <h5>{content.assessore_riferimento_role.title}: </h5>
              ) : item?.ruolo ? (
                <h5>{item.ruolo}: </h5>
              ) : (
                <></>
              )}
              <Link
                to={flattenToAppURL(item['@id'])}
                key={item['@id']}
                title={item.title}
                className="text-decoration-none mr-2"
              >
                <Chip
                  color="primary"
                  disabled={false}
                  large={false}
                  simple
                  tag="div"
                >
                  <ChipLabel tag="span">{item.title}</ChipLabel>
                </Chip>
              </Link>
            </>
          ))}
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default UOPageHeader;
