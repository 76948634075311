/**
 * Add your helpers here.
 * @module helpers
 * @example
 * export { Api } from './Api/Api';
 */

// Convert string date format - Farmacie
export const changeDateFormat = (inputDate, time) => {
  // expects dd-mm-YYYY
  const splitDate = inputDate.split('/');
  if (splitDate.count === 0) {
    return null;
  }
  const day = splitDate[0];
  const month = splitDate[1];
  const year = splitDate[2];

  // return month + '/' + day + '/' + year;
  return year + '-' + month + '-' + day + time;
  // Date.parse non funziona con dd-mm-YYYY ma con il mese specificato per primo altrimenti ritorna NaN
};
