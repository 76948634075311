import { GET_PS_STATUS } from '@package/actions';

const initialState = {
  error: null,
  hasError: false,
  result: {},
  loading: false,
  loaded: false,
  subrequests: {},
};

export const PSStatusReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_PS_STATUS}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  result: null,
                }),
                loading: true,
                loaded: false,
                error: null,
              },
            },
          }
        : {
            ...state,
            error: null,
            loading: true,
            loaded: false,
          };

    case `${GET_PS_STATUS}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                result: action.result,
                loading: false,
                loaded: true,
              },
            },
          }
        : {
            ...state,
            result: action.result,
            loading: false,
            loaded: true,
          };

    case `${GET_PS_STATUS}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: action.error,
                hasError: true,
                loading: false,
                loaded: true,
              },
            },
          }
        : {
            error: action.error,
            hasError: true,
            loading: false,
            loaded: true,
          };

    default:
      return state;
  }
};
