import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { SelectInput } from '@italia/components';
import { TextFilter } from '@italia/components/ItaliaTheme/Blocks/Common/SearchFilters';
import { Button } from 'design-react-kit/dist/design-react-kit';

const messages = defineMessages({
  find: {
    id: 'find',
    defaultMessage: 'Cerca',
  },
  search_keyword: {
    id: 'ambulatorio_search_keyword',
    defaultMessage: 'Cerca per nome',
  },
  comune: {
    id: 'ambulatorio_comune',
    defaultMessage: 'Comune',
  },
  distretto: {
    id: 'ambulatorio_distretto',
    defaultMessage: 'Distretto',
  },
});

const SearchBar = ({ filters, setFilters, options, doRequest }) => {
  const intl = useIntl();

  return (
    <div className="rounded bg-light" style={{ paddingBottom: '0.5em' }}>
      <form
        className="row d-flex search-container align-items-end justify-content-center flex-wrap"
        onSubmit={(event) => {
          event.preventDefault();
          doRequest({ page: 1 });
        }}
      >
        {/* parent_title o searchableText ? */}
        <div className="col col-lg-4">
          <label htmlFor="parent_title">
            {intl.formatMessage(messages.search_keyword)}
          </label>
          <TextFilter
            id="parent_title"
            className="text-search"
            value={filters['parent_title'] ?? ''}
            placeholder={intl.formatMessage(messages.search_keyword)}
            onChange={(id, value) => {
              setFilters({
                ...filters,
                [id]: value,
              });
            }}
          />
        </div>

        <div className="col col-lg-3">
          <label htmlFor="circoscrizione">
            {intl.formatMessage(messages.distretto)}
          </label>
          <SelectInput
            id="circoscrizione"
            value={filters['circoscrizione'] ?? ''}
            placeholder={intl.formatMessage(messages.distretto)}
            onChange={(opt) => {
              setFilters({ ...filters, circoscrizione: opt });
            }}
            options={options?.circoscrizione ?? []}
            isClearable={true}
            isSearchable={true}
          />
        </div>

        <div className="col col-lg-3">
          <label htmlFor="city">{intl.formatMessage(messages.comune)}</label>
          <SelectInput
            id="comune"
            value={filters['city'] ?? ''}
            placeholder={intl.formatMessage(messages.comune)}
            onChange={(opt) => {
              setFilters({ ...filters, city: opt });
              // checkClearComune(opt);
            }}
            options={options?.city ?? []}
            isClearable={true}
            isSearchable={true}
          />
        </div>

        <div className="col col-lg-1">
          <Button color="tertiary" icon={false} tag="button">
            {intl.formatMessage(messages.find)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
