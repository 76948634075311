export const GET_PS_STATUS = 'GET_PS_STATUS';

/*
getPSStatus fn:
data = {q:'searchable text', dipartimento:'uid dipartimento', sede:'uid sede', b_size:20}
*/

export function getPSStatus(ps_ids = [], subrequest) {
  return {
    type: GET_PS_STATUS,
    subrequest,
    request: {
      op: 'post',
      path: `/@get-triage-data`,
      data: {
        ps_id_list: ps_ids,
      },
    },
  };
}
