import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { FontAwesomeIcon } from '@italia/components/ItaliaTheme';
import { SelectInput } from '@italia/components';
import { Row, Col } from 'design-react-kit/dist/design-react-kit';

const messages = defineMessages({
  sort_by: {
    id: 'farmacia_sort_by',
    defaultMessage: 'Ordina per:',
  },
  comune: {
    id: 'farmacia_sort_by_comune',
    defaultMessage: 'Comune',
  },
  localita: {
    id: 'farmacia_sort_by_localita',
    defaultMessage: 'Località',
  },
});

const SearchSorting = ({ setSorting, sorting, isEditMode }) => {
  const intl = useIntl();

  const sortOptions = [
    {
      value: 'comune',
      label: intl.formatMessage(messages.comune),
    },
    {
      value: 'localita',
      label: intl.formatMessage(messages.localita),
    },
  ];

  return (
    <div className="search-sorting">
      <Row className="justify-content-end mr-lg-4">
        <Col lg="4" xl="4">
          <div className="input-wrap">
            <label htmlFor="search-sort-input">
              <FontAwesomeIcon icon={['fas', 'sort-amount-down']} />
              {intl.formatMessage(messages.sort_by)}
            </label>
            <SelectInput
              id="search-sort-input"
              value={sortOptions.filter((o) => o.value === sorting)[0]}
              placeholder={intl.formatMessage(messages.sort_by)}
              onChange={(opt) => {
                setSorting(opt.value);
              }}
              options={sortOptions}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SearchSorting;
