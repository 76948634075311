/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@italia/reducers';
import { rubricaReducer } from './rubrica';
import { farmaciaReducer } from './farmacia';
import { addressTreeReducer } from './address';
import { PSStatusReducer } from './ps';
/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  // Add your reducers here
  rubrica: rubricaReducer,
  farmacia: farmaciaReducer,
  addressTree: addressTreeReducer,
  PSStatus: PSStatusReducer,
};

export default reducers;
